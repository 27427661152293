.container {
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 2%;

    text-align: center;
}
p {
    font-size: 1.5em;
    line-height: 1.6;
}