.sample-background {
    margin-top: -2%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    margin-bottom: 5%;
}

.sample-background h2 {
    color: #8E8E8E;
    font-weight: 100;
}

.sample-column {
    flex: 33%;
    padding: 10px;
  }
  
.sample-row {
    display: flex;
}

@media only screen and (max-width: 600px) {
    .sample-background {
        margin-left: 0%;
        margin-right: 0%;
    }
    
    .sample-background > h2 {
        font-size: 1em;
    }

    .sample-row {
        flex-direction: column;
        align-items: center;
    }

    .sample-column {
        flex: 100%;
        padding-top: 1%;
        padding-bottom: 1%;
    }

    .sample-column > img {
        height: 250px;
        width: 100vw;
        object-fit: cover;
    }
}