.weddings-container {
    margin-top: 5%;
}

.weddings-title {
    text-align: center;
    margin-bottom: 5%;
    color: #8E8E8E;
    font-weight: 100;
}

.wedding-cards-container {
    display: grid;
    grid-template-columns: auto auto;
    margin-left: 20%;
    margin-right: 20%;
    vertical-align: middle;
    justify-items: center;
}

@media only screen and (min-width: 600px) and (max-width: 1400px) {
    .wedding-cards-container {
        grid-template-columns: auto auto;
        margin-left: 2%;
        margin-right: 2%;

    }

    .wedding-card {
    }
}

@media only screen and (max-width: 600px) {
    .weddings-title {
        font-size: 1em;
    }

    .wedding-cards-container {
        grid-template-columns: auto;
        margin-left: 2%;
        margin-right: 2%;
    }

    .wedding-description > h2 {
        font-size: 1em;
    }

    .wedding-description > p {
        font-size: 1em;
    }
  }

.wedding-card {
    display: table-cell;
    width: 400px;
}

.wedding-carousel {
}

.wedding-description {
    text-align: center;
    color: #8E8E8E;
}

.wedding-description h2 {
    font-weight: 100;
    margin-bottom: 0%;
}

.wedding-description p {
    font-weight: 100;
    margin-top: 0%;
}

#wedding-card-1 {
}

#wedding-card-2 {
}

#wedding-card-3 {
}

#wedding-card-4 {
}