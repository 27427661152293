.contact-container {
    background-image: url('../../public/assets/about-background.jpg');
    background-size: cover;
    text-align: center;
}

.contact-container h2 {
    font-weight: 100;
    color: #8E8E8E;
}

.contact-social-container {
    
}

.social-button {
    margin: 5%;
    display: inline-block;
}