.legend {
    background: none !important;
    opacity: 1 !important;
    font-family: 'Times New Roman', Times, serif;
}

.homepage > .carousel-root {
    height: 100vh;
    margin-bottom: 0%;
    display: inline-block;
}

.homepage-name {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'Times New Roman', Times, serif;
    color: white;
    font-size: 4em;
}

.homepage-description-container {
    font-size: 0.5em;
}
.homepage-description {
    margin-top: 0%;
    margin-left: 10%;
    margin-right: 10%;
}

.homepage-bar {
    border-color: white;
    border-top: 1px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 10%;
    margin-right: 10%;
}

@media only screen and (max-width: 600px) {
    .homepage-name {
        font-size: 2em;
        white-space: nowrap;
    }  
    .homepage-carousel-img {
        min-height: 100vh !important;
        object-fit: cover;
    } 
  }