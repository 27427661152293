.description-background {
    background-image: url('../../public/assets/description-background.jpg');
    opacity: 99%;
    background-size: cover;
    margin-top: -2%;
}

.pack-description-container {
    color: #8E8E8E;
    padding-top: 2%;
    padding-bottom: 2%;
}

.pack-description-container h2 {
    font-weight: 100;
    margin-bottom: 5%;
    margin-top: 5%;
}

.pack-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.pack {
    max-width: 600px;
    width: 100%;
}

.pack-description-container > ul {
    list-style-position: inside;
}

.description-li {
    font-size: large;
    margin-top: 2%;
    margin-bottom: 2%;
}

.description-p {
    margin-top: 0%;
    font-style: italic;
}

@media only screen and (max-width: 600px) {
    .pack-container {
        grid-template-columns: auto;
    }

    .pack-description-container {
        margin-right: 8%;
        margin-left: 8%;
    }

    .pack-description-container > ul {
        padding-left: 0%;
    }

    .description-li {
        font-size: 1em;
    }

    .description-p {
        font-size: 1em;
    }

    .pack-description-container h2 {
        white-space: nowrap;
        font-weight: 100;
        font-size: 1em;
        margin-bottom: 10%;
    }

}