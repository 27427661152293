.banner-container-parallax {
    max-height: 700px;
}

.video-background {
    overflow: hidden;
    object-fit: cover;
    height: 500px;
    width: 100%;
    z-index: -100;
    filter: brightness(50%);
}

@media only screen and (max-width: 600px) {
    .banner-container-parallax {
        display: none;
    }
}