.navbar-container {
    width: 100vw;
    z-index: 1;
    position: absolute;
}

.navbar {
    display: grid;
    grid-template-columns: 1fr 1fr 3fr 1fr 1fr;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0%;
    align-items: center;
}

.tab {
    list-style-type: none;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
}

.tab a {
    text-decoration: none;
    color: white;
}

.logo {
    width: 50%;
}

@media only screen and (max-width: 600px) {
    .navbar {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin-top: 2%;
    }

    .logo {
        width: 100%;
    }

    .tab {
        font-size: 0.8em;
        white-space: nowrap;
    }

    .tab-logo { grid-area: 1 / 3 / 3 / 6; }
    .tab-0 { grid-area: 3 / 1 / 4 / 2; }
    .tab-1 { grid-area: 3 / 3 / 4 / 4; }
    .tab-2 { grid-area: 3 / 5 / 4 / 6; }
    .tab-3 { grid-area: 3 / 7 / 4 / 8; }
}