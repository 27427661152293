.about-background {
    background-image: url('../../public/assets/about-background.jpg');
    background-size: cover;
    margin-top: -2%;
}

.about-container {
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 5%;
    text-align: center;
}

.about-container h2 {
    color: #8E8E8E;
    font-weight: 100;
}

.about-title {
    margin-top: 5%;
    max-width: 600px;
    width: 100%;
}

.about-content {
    margin-top: 5%;
    margin-bottom: 2%;
}

.about-content p {
    color: #8E8E8E;
    font-size: 1.1em;
}

.about-signature {
    width: 20%;
    margin-bottom: 5%;
}

@media only screen and (max-width: 600px) {
    .about-container {
        margin-left: 10%;
        margin-right: 10%;
    }

    .about-container > h2 {
        font-size: 1em;
    }

    .about-content > p {
        font-size: 0.8em;
    }

    .about-signature {
        width: 30%;
    }
}