.stats-container {
    text-align: center;
    background-color: white;
}

.stats-img-container {
    margin-left: 20%;
    margin-right: 20%;
}

.stats-img {
    width: 100%;
}

.stats-carousel-image img {
    max-height: 500px;
}

@media only screen and (max-width: 600px) {
    html, body {
        overflow-x: hidden;
    }
    .img-stats-card {
        max-height: 300px !important;
    }

    .stats-img-container {
        margin-left: 1%;
        margin-right: 1%;
    }
}